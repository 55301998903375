<template>
    <div>
      <form
        @submit.prevent="!education_id ? saveEducation() : Update(education_id)"
        @keydown="form.onKeydown($event)"
      >

        <b-row>
          <b-col lg="8">
            <b-row>
              <b-col cols="12" lg="6">
                <label>Education Level*</label>
                <Select v-model="form.level_id" filterable>
                  <Option
                    v-if="levels.length"
                    v-for="level in levels"
                    :value="level.id"
                    :key="level.id"
                    >{{ level.name }}</Option
                  >
                </Select>
                <has-error :form="form" field="level_id"></has-error>
              </b-col>

              <b-col cols="12" lg="6">
                <div class="form-group">
                  <label>Degree</label>
                  <Input
                    v-model="form.degree"
                    placeholder="Enter Your Degree Name"
                    style="width: 100%"
                    type="text"
                  />
                  <has-error :form="form" field="degree"></has-error>
                </div>
              </b-col>

              <b-col lg="12">
                <b-row>
                  <b-col cols="12" lg="6">
                    <div class="form-group">
                      <label>Starting Date*</label>
                      <DatePicker
                        v-model="starting_date"
                        style="width: 100%"
                        type="date"
                        placeholder="Select Starting date"
                      />
                      <has-error :form="form" field="starting_date"></has-error>
                    </div>
                  </b-col>

                  <b-col cols="12" lg="6">
                    <div class="form-group">
                      <label>Ending Date</label>
                      <DatePicker
                        v-model="ending_date"
                        type="date"
                        placeholder="Select Ending date"
                        style="width: 100%"
                        :disabled="form.running"
                      />
                      <has-error :form="form" field="ending_date"></has-error>
                    </div>
                  </b-col>
                </b-row>
              </b-col>
              <b-col>
                <div class="form-group text-left mb-1">
                  <p>
                    <Checkbox border v-model="form.running">
                      &nbsp;Running
                    </Checkbox>
                  </p>
                  <has-error :form="form" field="running"></has-error>
                </div>
              </b-col>
            </b-row>
          </b-col>
          <b-col lg="4" cols="12">
            <label>Institute*</label>
            <Input
              v-model="form.institute"
              placeholder="Enter Your Institute Name"
              style="width: 100%"
              type="textarea"
              :rows="4"
            />
            <has-error :form="form" field="institute"></has-error>
          </b-col>
        </b-row>

        <div class="w-100 text-right">
          <b-row class="pt-1">
            <b-col class="d-flex justify-content-between" cols="4" lg="6">
              <router-link :to="'/profile/' + $route.params.id" v-if="profile">
                <b-button
                variant="primary"
                class="ml-1">
                Back to profile
              </b-button>
              </router-link>
            </b-col>
            <b-col cols="8" lg="6">
              <b-button variant="primary" type="submit" :disabled="form.busy">
                {{ education_id ? "Update" : "Add" }}
              </b-button>
              <b-button variant="danger" class="ml-1" @click="clear()">
                Clear
              </b-button>
            </b-col>
          </b-row>
        </div>
      </form>
      <hr class="mt-2" />

      <div
        class="card border"
        v-for="(education, index) in educations"
        v-if="educations.length"
        :key="index"
      >
        <div class="card-body">
          <b-row>
            <b-col
              cols="12"
              lg="12"
              class="d-flex justify-lg-content-between flex-column flex-lg-row"
            >
              <div class="order-1 order-lg-0">
                <h5 class="card-title font-weight-bolder font-weight-bold">
                  {{ education.institute }}
                </h5>
              </div>
              <div class="order-0 order-lg-1 ml-auto">
                <button
                  class="btn btn-primary btn-sm"
                  @click="edit(education.id)"
                >
                  <feather-icon icon="EditIcon" />
                </button>
                <button
                  class="btn btn-danger btn-sm ml-1"
                  @click="destroy(education.id)"
                >
                  <feather-icon icon="TrashIcon" />
                </button>
              </div>
            </b-col>

            <b-col cols="12 mb-1">
              <h6 class="card-subtitle text-secondary">{{ education.degree }}</h6>
            </b-col>
            <b-col cols="12">
              <h6 class="card-subtitle mb-2 text-secondary">
                {{ education.starting_date }} - {{ education.ending_date }}
              </h6>
            </b-col>
          </b-row>
        </div>
      </div>
    </div>
  </template>

  <script>
  import {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BForm,
    BButton,
  } from "bootstrap-vue";
  import { HasError, AlertError } from "vform/src/components/bootstrap5";

  export default {
    props: [
      "profile",
    ],
    data() {
      return {
        form: new Form({
          level_id: null,
          degree: null,
          institute: null,
          starting_date: null,
          ending_date: null,
          running: null,
        }),
        education_id: null,
        levels: [],
        starting_date: null,
        ending_date: null,
        educations: [],
      };
    },
    components: {
      BRow,
      BCol,
      BFormGroup,
      BFormInput,
      BFormCheckbox,
      BForm,
      BButton,
      HasError,
      AlertError,
    },
    mounted() {
      this.showEducation();
      this.getLevel();
    },
    methods: {
      clear() {
        this.education_id = null;
        this.form.level_id = null;
        this.form.degree = null;
        this.form.institute = null;
        this.form.starting_date = null;
        this.starting_date = null;
        this.ending_date = null;
        this.form.ending_date = null;
        this.form.status = true;
        this.form.running = false;
      },

      formatDate(date) {
        if (date) {
          const year = date.getFullYear();
          const month = String(date.getMonth() + 1).padStart(2, "0");
          const day = String(date.getDate()).padStart(2, "0");
          return `${year}-${month}-${day}`;
        }
        return null;
      },
      getLevel() {
        axios
          .get("/app/candidate/get/level")
          .then((res) => {
            this.levels = res.data.levels;
          })
          .catch((e) => {
            this.e(e.data.error);
          });
      },
      saveEducation() {
        this.form.starting_date = this.formatDate(this.starting_date);
        this.form.ending_date = this.formatDate(this.ending_date);
        this.form
          .post("/app/candidate/add/education/"+ this.$route.params.id)
          .then((response) => {
            this.s(response.data.message);
            this.clear();
            this.showEducation();
          })
          .catch((e) => {
            this.e(e.data.error);
          });
      },
      edit(id) {
        axios
          .get("/app/candidate/edit/education/" + id + '/' + this.$route.params.id)
          .then((res) => {
            this.education_id = res.data.education.id;
            this.form.level_id = res.data.education.level_id;
            this.form.degree = res.data.education.degree;
            this.form.institute = res.data.education.institute;
            this.starting_date = res.data.education.starting_date;
            this.ending_date = res.data.education.ending_date;
            this.form.running = !res.data.education.ending_date ? true : false;
          })
          .catch((e) => {
            this.e(e.data.error);
          });
      },
      Update(id) {
        this.form.starting_date = this.formatDate(this.starting_date);
        this.form.ending_date = this.formatDate(this.ending_date);
        this.form
          .put("/app/candidate/update/education/" + id +'/' + this.$route.params.id)
          .then((res) => {
            this.s(res.data.message);
            console.log(this.profile)
            this.clear();
            this.showEducation();
          })
          .catch((e) => {
            this.e(e.data.error);
          });
      },
      destroy(id) {
        axios
          .get("/candidate/delete/education/" + id + '/' + this.$route.params.id)
          .then((response) => {
            this.s(response.data.message);
            this.showEducation();
          })
          .catch((e) => {
            this.e(e.data.error);
          });
      },
      showEducation() {
        axios
          .get("/app/candidate/get/education/" + this.$route.params.id)
          .then((res) => {
            this.educations = res.data.data;
          })
          .catch((e) => {
            this.e(e.data.error);
          });
      },
    },
  };
  </script>

  <style scoped>
  .onboard-form .card-body {
    display: block !important;
  }
  </style>