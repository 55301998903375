<template>
  <b-form @submit.prevent="saveSkill" class="w-100">
    <b-button variant="primary" @click="addSkill()" class="mb-2 ml-auto">
      <feather-icon icon="PlusCircleIcon" size="18" />
    </b-button>
    <b-row
      v-for="(skill, index) in form.skills"
      :key="index"
      class="align-items-end w-100 mb-1"
    >
      <b-col cols="12" lg="5" style="min-height: 70px">
        <label>Skill</label>
        <Select v-model="skill.skill_id" filterable>
          <Option
            v-if="filteredSkillOptions(index).length"
            v-for="item in filteredSkillOptions(index)"
            :value="item.id"
            :key="item.id"
            >{{ item.name }}</Option
          >
        </Select>
        <small
          v-if="form.errors.has('skills.' + index + '.skill_id')"
          class="text-danger"
          >This field is required
        </small>
      </b-col>

      <!-- email -->
      <b-col cols="12" lg="3" style="min-height: 70px">
        <label>Proficiency</label>
        <Select v-model="skill.proficiency" filterable>
          <Option
            v-for="item in proficiency"
            :value="item.name"
            :key="item.name"
            >{{ item.name }}</Option
          >
        </Select>
        <small
          v-if="form.errors.has('skills.' + index + '.proficiency')"
          class="text-danger"
          >This field is required
        </small>
      </b-col>

      <!-- mobile -->
      <b-col cols="12" lg="3" style="min-height: 70px">
        <label>Years Of Experience</label>
        <InputNumber
          v-model="skill.years_of_experience"
          placeholder="Years Of Experience"
          style="width: 100%"
          type="number"
          :min="0"
        />
        <small
          v-if="form.errors.has('skills.' + index + '.years_of_experience')"
          class="text-danger"
          >This field is required
        </small>
      </b-col>
      <b-col cols="12" lg="1" style="min-height: 50px">
        <b-button
          variant="danger"
          @click="removeSkill(index)"
          class="w-100"
          :disabled="form.skills.length == 1"
        >
          <feather-icon icon="XCircleIcon" size="18" />
        </b-button>
      </b-col>
    </b-row>

    <div>
      <b-button variant="primary" type="submit" :disabled="form.busy">
        Save
      </b-button>
      <router-link :to="'/profile/' + $route.params.id" v-if="profile">
        <b-button variant="primary" class="ml-1"> Back to profile </b-button>
      </router-link>
    </div>
  </b-form>
</template>

<script>
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BForm,
  BButton,
} from "bootstrap-vue";
import { HasError, AlertError } from "vform/src/components/bootstrap5";

export default {
  props: ["profile"],
  data() {
    return {
      form: new Form({
        skills: [
          {
            skill_id: null,
            proficiency: null,
            years_of_experience: null,
          },
        ],
      }),
      showSkillLength: null,
      skillsData: {},
      proficiency: [
        { name: "EXPERT" },
        { name: "ADVANCED" },
        { name: "EXPERIENCED" },
        { name: "BEGINNER" },
      ],
    };
  },
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BForm,
    BButton,
    HasError,
    AlertError,
  },
  computed: {
    filteredSkillOptions() {
      return (index) => {
        const selectedSkillIds = this.form.skills
          .slice(0, index)
          .map((skill) => skill.skill_id);
        return this.skillsData.filter(
          (skill) => !selectedSkillIds.includes(skill.id)
        );
      };
    },
  },
  mounted() {
    this.getSkill();
    this.showSkill();
  },
  methods: {
    getSkill() {
      axios
        .get("/app/candidate/all/skills")
        .then((res) => {
          this.skillsData = res.data.skills;
        })
        .catch((e) => {
          this.e(e.response.data.error);
        });
    },
    showSkill() {
      axios
        .get("/app/candidate/skills/" + this.$route.params.id)
        .then((res) => {
          let skills = res.data.skills;
          let length = skills.length;
          if (length > 0) {
            this.showSkillLength = length;
            this.form.skills = [];
            skills.forEach((data) => {
              this.form.skills.push({
                skill_id: data.skill_id,
                proficiency: data.proficiency,
                years_of_experience: data.years_of_experience,
              });
            });
          }
        })
        .catch((e) => {
          this.e(e.response.data.error);
        });
    },
    saveSkill() {
      this.form
        .post("/app/candidate/skills/" + this.$route.params.id)
        .then((res) => {
          this.showSkill();
          this.s(res.data.message);
        })
        .catch((e) => {
          this.e(e.response.data.message);
        });
    },
    addSkill() {
      this.form.skills.push({
        skill_id: null,
        proficiency: null,
        years_of_experience: null,
      });
    },
    removeSkill(index) {
      this.form.skills.splice(index, 1);
    },
  },
};
</script>
